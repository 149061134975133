<template>
  <div class="px-2 sm:px-10 py-4">
    <Header v-bind="content.profile" isTitle @onClick="(item) => $emit('onClickItem', item)" title="Ecosystem Settings" />
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2">
        <div class="flex justify-between items-center">
          <Text size="xl" weight="semibold" color="gray-900" :content="'Ecosystem Info'" />
          <Icon name="InformationCircleIcon" size="6" color="gray-400" />
        </div>
        <Text @click="isOpen = !isOpen" size="sm" color="primary-600" :content="isOpen ? 'hide' : 'show'"
          decoration="underline" custom-class="cursor-pointer mt-2" />
        <div v-if="isOpen">
          <p class="leading-4 mt-4">
            <Text size="xs" color="black" weight="bold" content="Name: " element="span" />
            <Text size="xs" color="black" weight="base"
              :content="`An easy way to reference your idea, even if it's not the &#8221official&#8221 name`"
              element="span" />
          </p>
          <p class="leading-4 mt-3">
            <Text size="xs" color="black" weight="bold" content="Type: " element="span" />
            <Text size="xs" color="black" weight="base"
              :content="`Select whether you're organization is a company, nonprofit, school, or something else`"
              element="span" />
          </p>
          <p class="leading-4 mt-3">
            <Text size="xs" color="black" weight="bold" content="Logo: " element="span" />
            <Text size="xs" color="black" weight="base"
              :content="`This will show up on ventures that you invite to be a part of your ecosystem.`" element="span" />
          </p>
        </div>

      </div>
      <div class="col-span-5">
        <div class="shadow-lg h-fit rounded-md">
          <div class="px-5 py-8 grid grid-cols-2 gap-4 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
            <div v-for="key in Object.keys(content.info)" :key="key" class="w-full">
              <Input v-if="content.info[key].type === 'text'" :label="content.info[key].label"
                v-model:value="content.info[key].value" :type="content.info[key].type" />
              <Select v-if="content.info[key].type === 'select'" :items="content.info[key].items"
                :label="content.info[key].label" v-model:selected-item="content.info[key].value" custom-class="w-full" />
            </div>
            <div>
              <Text size="sm" weight="semibold" color="gray-700" content="Logo" custom-class="mb-2" />
              <FileUploader v-bind="{
                emptyText: 'Select a file',
                emptyIcon: 'CloudUploadIcon',
                editText: 'Edit',
                logo: logo,
              }" v-model:file="logoFile" @updateLogo="img => logo = img" />
            </div>
          </div>
          <div
            class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
            <div />
            <div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
              <Button content="Cancel" :variant="null" customClass="bg-white text-black underline px-2 bg-gray-100" />
              <Button content="Save" variant="primary" customClass="ml-4 px-4" :isLoading="isSavingEcosystem"
                @click="onSaveEcosystem(content.info)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2 flex items-center h-12 space-x-2">
        <Text size="xl" weight="semibold" color="gray-900" :content="'Ecosystem Users'" />
        <Badge :is-rounded="true" text-size="sm" px="3" py="2" bg-color="green-100" text-color="green-800"
          :label="get(content, 'contacts.length') || 0" text-weight="medium" />
      </div>
      <div class="col-span-5">
        <div class="h-fit rounded-md">
          <div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
            <div v-for="contact in (content.contacts || []).filter(o => !(o && o.isArchived))" :key="contact.type" class="grid grid-cols-4 py-3 px-2">
              <div class="col-span-2">
                <Text size="sm" weight="semibold" color="black"
                  :content="(get(contact, 'firstName') || '') + ' ' + (get(contact, 'lastName') || '') + (get(state.currentUser, 'id') === get(contact, 'id') ? ' (you)' : '')" />
              </div>
              <div class="col-span-1">
                <Badge :label="getUserType(contact)" isLeft bg-color="gray-500" is-rounded px="3" py="1"
                  text-color="white" />
              </div>
              <div class="col-span-1 flex justify-end" v-if="get(state.currentUser, 'id') !== get(contact, 'id')">
                <!--									<a class="px-1">-->
                <!--										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>-->
                <!--									</a>-->
                <a @click="onRemoveEcosytemUser(contact)" class="px-1">
                  <Icon name="TrashIcon" color="red-600" class="hover:text-red-900" />
                </a>
              </div>
            </div>
          </div>
          <span @click="onInvitePeople" class="cursor-pointer">
            <Text size="sm" color="primary-600" content="Invite someone" decoration="underline"
              custom-class="cursor-pointer mt-5" />
          </span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2">
        <Text size="xl" weight="semibold" color="gray-900" :content="'Payment Method'" />
        <Text size="xs" color="black" weight="base"
          :content="`This is what we will use to pay for your monthly $199 ecosystem plan.`"
          custom-class="mt-4 leading-5" />
      </div>
      <div class="col-span-5">
        <div class="h-fit">
          <div v-if="get(content, 'payments.length') > 0 && !isAddPayment"
            class="bg-white border border-gray-300 rounded-md px-5 py-8">
            <div>
              <div>
                <Text size="sm" weight="semibold" content="Payment Method" />
                <div class="space-y-2 mt-3">
                  <div v-for="payment in content.payments" :key="payment.id"
                    class="flex justify-between items-center px-4 py-2 rounded-md bg-gray-200">
                    <div class="flex items-center space-x-2">
                      <img :src="payment.image" class="w-7" />
                      <Text size="xs" color="black" :content="`Ending in *${payment.last4}`" weight="semibold" />
                    </div>
                    <Text v-if="get(content, 'payments.length') > 1" @click="() => $emit('onDeletePayment', payment)" size="sm"
                      color="rose-600" :content="'delete'" decoration="underline" custom-class="cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="get(content, 'payments.length') === 0 || isAddPayment">
            <div
              class="px-5 py-8 grid grid-cols-2 gap-4 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
              <Text size="sm" weight="semibold" content="Add Payment Method" custom-class="mb-4" />
              <div v-for="(form, index) in content.paymentForm" :key="index" class="w-full"
                :class="`col-span-${form.cols}`">
                <Input :label="form.label" :placeholder="form.placeholder" v-model:value="formData[form.name]"
                  :type="form.type" :input-mask="form.mask" />
              </div>
            </div>
            <div
              class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
              <div />
              <div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
                <Button @click="() => { isAddPayment = false; formData = {} }" content="Cancel" :variant="null"
                  customClass="bg-white text-black underline px-2 bg-gray-100" />
                <Button content="Save" variant="primary" customClass="ml-4 px-4" :isLoading="isSavingPaymentMethod"
                  @click="async () => { await onSavePaymentMethod(formData); isAddPayment = false; formData = {} }" />
              </div>
            </div>
          </div>
          <Text v-if="get(content, 'payments.length') > 0" @click="() => { isAddPayment = true; formData = {} }" size="sm"
            color="primary-600" content="Add New Payment" decoration="underline" custom-class="cursor-pointer mt-5" />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2 flex items-center space-x-2">
        <Text size="xl" weight="semibold" color="gray-900" :content="'Transaction History'" />
        <Badge :is-rounded="true" text-size="sm" px="3" py="2" bg-color="green-100" text-color="green-800"
          :label="get(content, 'ecosystems.length') || 0" text-weight="medium" />
      </div>
      <div class="col-span-5">
        <div class="h-fit rounded-md">
          <div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300"
            v-if="get(content, 'ecosystems.length') > 0">
            <div v-for="eco in content.ecosystems" :key="eco.id" class="grid grid-cols-4 py-3 px-2">
              <div class="col-span-2">
                <Text size="sm" weight="semibold" color="black" :content="`${moment(eco.createdAt).format('M/D/YY')}`" />
              </div>
              <div class="col-span-1">
                <Text size="sm" weight="semibold" color="black" :content="getAmount(eco.amountPaid)" />
              </div>
              <div class="col-span-1">
                <Badge :label="eco.status" bg-color="gray-500" is-rounded px="3" py="1" text-color="white"
                  v-if="eco.status" />
              </div>
              <!--<div class="col-span-1 flex justify-end">
                									<a class="px-1">
               										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>
                									</a>
                <a class="px-1">
                  <Icon name="TrashIcon" color="red-600" class="hover:text-red-900"/>
                </a>
              </div>-->
            </div>
          </div>
          <EmptyState v-else class="" isFull :description="'No transactions available!'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNavigationBar from "../../organisms/SideNavigationBar/SideNavigationBar.vue";
import Header from "../../molecules/PageViewHeader/Profile/Profile";
import Text from "../../atoms/Text/Text";
import Input from "../../molecules/Inputs/Component/Component";
import Select from "../../molecules/Selects/Component/Component";
import Textarea from "../../molecules/Textareas/Simple/Simple";
import Button from "../../atoms/Button/Button";
import Badge from "../../molecules/Badge/Basic/Basic";
import Icon from "../../atoms/Icons/Icons";
import Toggle from "../../molecules/Toggles/Simple/Simple";
import DeleteModal from "../../organisms/Modals/SimpleAlert/SimpleAlert";
import FileUploader from "../../molecules/FilePickers/SimpleWithEmptyStateAndImagePreview/SimpleWithEmptyStateAndImagePreview";
import { formatCurrencyDec } from "../../../utils/utils";
import EmptyState from '../../organisms/EmptyState/Simple/Simple'
import moment from "moment";
import { get } from "lodash";

/**
 * - Use it to show a settings view with sidebar
 */
export default {
  components: {
    SideNavigationBar,
    Header,
    Text,
    Input,
    Button,
    Badge,
    Icon,
    Textarea,
    Toggle,
    DeleteModal,
    Select,
    moment,
    FileUploader,
    EmptyState,
  },
  props: {
    content: {
      type: Object,
      default: () => { },
    },
    isSavingEcosystem: {
      type: Boolean,
      default: false,
    },
    onRemoveEcosytemUser: {
      type: Function,
      default: () => { },
    },
    onInvitePeople: {
      type: Function,
      default: () => { },
    },
    onSaveEcosystem: {
      type: Function,
      default: () => { },
    },
    isSavingPaymentMethod: {
      type: Boolean,
      default: false,
    },
    onSavePaymentMethod: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      isOpen: true,
      isAccelerate: true,
      moment,
      get,
      logo: this.content?.logo,
      logoFile: null,
      isAddPayment: false,
      formData: {}
    };
  },
  watch: {
    content: function (val) {
      console.log(val, 'val')
      if (val) this.logo = this.content?.logo
    },
    logoFile: function (val) {
      this.$emit('onChangeLogo', val)
    }
  },
  methods: {
    getAmount(amount) {
      return formatCurrencyDec(amount);
    },
    getUserType(user) {
      console.log(user)
      if (user?.userType) {
        return user?.userType
      } else if (user?.userGroups?.find(u => u?.group?.name === 'ecosystem-admin-user')) {
        return 'Admin'
      }
    }
  },
};
</script>
